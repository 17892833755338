import { ModalidadeCurso, ModalidadeCursoPorExtenso } from 'src/tipos'

export const SELECT_MODALIDADES = [
  {
    id: ModalidadeCurso[ModalidadeCurso.Livre],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Livre]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEad],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEad]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFlex],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEadFlex]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFull],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEadFull]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoPresencial],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoPresencial]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.MBAEad],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.MBAEad]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.MBAEadFull],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.MBAEadFull]
  },
  {
    id:
      ModalidadeCurso[
        ModalidadeCurso.PosGraduacaoEadFullIntegracaoInstitucional
      ],
    texto:
      ModalidadeCursoPorExtenso[
        ModalidadeCurso.PosGraduacaoEadFullIntegracaoInstitucional
      ]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadIntegracaoInstitucional],
    texto:
      ModalidadeCursoPorExtenso[
        ModalidadeCurso.PosGraduacaoEadIntegracaoInstitucional
      ]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.Graduacao],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Graduacao]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.Evento],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Evento]
  }
]

export const SELECT_MODALIDADES_PESQUISA_SATISFACAO = [
  {
    id: ModalidadeCurso[ModalidadeCurso.Livre],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Livre]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEad],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEad]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFlex],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEadFlex]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFull],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEadFull]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.Evento],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Evento]
  }
]
