import {
  CursoSituacao,
  ModalidadeCurso,
  ModalidadeCursoPorExtenso
} from 'src/tipos'

export const OPCOES_MODALIDADE = [
  {
    id: ModalidadeCurso[ModalidadeCurso.Livre],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Livre]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEad],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEad]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFlex],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEadFlex]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFull],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEadFull]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoPresencial],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoPresencial]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.MBAEad],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.MBAEad]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.MBAEadFull],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.MBAEadFull]
  },
  {
    id:
      ModalidadeCurso[
        ModalidadeCurso.PosGraduacaoEadFullIntegracaoInstitucional
      ],
    texto:
      ModalidadeCursoPorExtenso[
        ModalidadeCurso.PosGraduacaoEadFullIntegracaoInstitucional
      ]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadIntegracaoInstitucional],
    texto:
      ModalidadeCursoPorExtenso[
        ModalidadeCurso.PosGraduacaoEadIntegracaoInstitucional
      ]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.Graduacao],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Graduacao]
  },
  {
    id: ModalidadeCurso[ModalidadeCurso.Evento],
    texto: ModalidadeCursoPorExtenso[ModalidadeCurso.Evento]
  },
  {
    id: 'Todas',
    texto: 'Todas'
  }
]

export const OPCOES_SITUACAO = [
  {
    id: CursoSituacao[CursoSituacao.Ativo],
    texto: 'Ativo'
  },
  {
    id: CursoSituacao[CursoSituacao.Inativo],
    texto: 'Inativo'
  },
  {
    id: CursoSituacao[CursoSituacao.Todos],
    texto: 'Todas'
  }
]
