import React, { useEffect, useMemo, useRef, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useParams } from 'react-router-dom'

import { Scope } from '@unform/core'
import {
  BotaoStep,
  FormRef,
  FormUnform,
  ResumoCurso,
  WizardContainer,
  SelectUnform,
  Checkbox,
  InputUnform,
  FuncoesMoeda,
  Informacao,
  scrollParaTopo,
  SelectOpcao,
  IconeInformacao,
  Tooltip,
  Cores,
  CheckboxUnform,
  TelefoneInputUnform,
  CpfCnpjInputUnform,
  focarPrimeiroCampoComErro,
  Botao
} from 'src/componentes'
import { dataFormatadaComBarras } from 'src/componentes/funcoes/data-hora'
import {
  SELECT_NACIONALIDADES,
  SELECT_PAISES,
  SELECT_TIPO_USUARIO
} from 'src/dados-estaticos'
import { ObterUsuarioDTO } from 'src/dto'
import { IconeLabel } from 'src/paginas/aluno/painel-financeiro/componentes/styles'
import { Api } from 'src/servicos'
import { PlanoPagamentoReingresso } from 'src/servicos/api/tipos'
import {
  FormaPagamentoCobranca,
  ResponsavelFinanceiro,
  TipoUsuario,
  Nacionalidade,
  PaisResidencia,
  PlanoPagamentoMatriculaForm,
  UsuarioSituacao,
  CupomValido,
  Curso,
  OpcaoTCC
} from 'src/tipos'
import { v4 } from 'uuid'

import ReingressoContainer from '../container'
import {
  schemaPosGraduacaoPlanoPagamento,
  schemaPosGraduacaoPlanoPagamentoNovoResponsavelFinanceiro,
  schemaResponsavelFinanceiroPF
} from '../schema'
import {
  ContainerConteudo,
  ContainerAcoes,
  ContainerCadastro,
  Linha,
  ContainerPossuiResponsavelFinanceiro,
  ContainerPequeno,
  ContainerGrande,
  ContainerCheio,
  ContainerMedio,
  ContainerInfos,
  ContainerInfosPagamento,
  Info,
  TituloInfo,
  TituloIsento,
  ContainerInputCupom,
  ContainerCupom
} from '../style'
import {
  ContainerOpcoes,
  ContainerValores,
  CupomContainer,
  OpcaoDados
} from './styles'

export const PlanoPagamento: React.FC = () => {
  const formRef = useRef<FormRef>(null)
  const formCupomRef = useRef<FormRef>(null)

  const { id } = useParams<{ id?: string }>()

  const {
    dados,
    curso,
    definirPlanoPagamento,
    definirResponsavelFinanceiro,
    definirPasso
  } = ReingressoContainer.useContainer()

  const { mudarEtapa, voltarWizard, passo } = WizardContainer.useContainer()

  const [carregandoAvancar, definirCarregandoAvancar] = useState(false)
  const [carregandoVoltar, definirCarregandoVoltar] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const [listaPlanosPagamento, definirListaPlanosPagamento] = useState<
    PlanoPagamentoReingresso[]
  >()
  const [temCupom, definirTemCupom] = useState(
    !!dados?.matriculaDestino?.idCupom
  )

  const [matriculaEdicao] = useState(dados?.matriculaDestino)
  const [cupom, definirCupom] = useState<CupomValido>({} as CupomValido)

  const [codigoCupom, definirCodigoCupom] = useState<string>(
    matriculaEdicao?.codigoCupom
  )

  const [
    responsavelFinanceiro,
    definirResponsavelFinanceiroLocal
  ] = useState<ResponsavelFinanceiro>(
    matriculaEdicao?.responsavelFinanceiro ?? {
      possuiResponsavelFinanceiro: false
    }
  )

  const [
    tipoUsuarioResponsavelFinanceiro,
    definirTipoUsuarioResponsavelFinanceiro
  ] = useState<TipoUsuario>(
    matriculaEdicao?.responsavelFinanceiro?.usuarioResponsavel?.tipoUsuario
  )

  const [
    nacionalidadeResponsavelFinanceiro,
    definirNacionalidadeResponsavelFinanceiro
  ] = useState(Nacionalidade[Nacionalidade.brasileira])

  const [paisEndereco, definirPaisEndereco] = useState<string>(
    PaisResidencia.Brasil
  )

  const [
    usuarioResponsavelExistente,
    definirUsuarioResponsavelExistente
  ] = useState<ObterUsuarioDTO>(
    matriculaEdicao?.responsavelFinanceiro?.usuarioResponsavel
      ? ({
          ...matriculaEdicao?.responsavelFinanceiro?.usuarioResponsavel,
          endereco: null
        } as ObterUsuarioDTO)
      : null
  )

  const [
    enderecoResponsavelDiferente,
    definirEnderecoResponsavelDiferente
  ] = useState(false)

  const [
    formaPagamento,
    definirFormaPagamento
  ] = useState<FormaPagamentoCobranca>(null)

  const [
    listaPlanoPagamentoSelect,
    definirListaPlanoPagamentoSelect
  ] = useState<Array<SelectOpcao>>([])

  const [listaPlanoPagamento, definirListaPlanoPagamento] = useState<
    Array<PlanoPagamentoReingresso>
  >([])

  const [
    planoPagamentoSelecionadoSelect,
    definirPlanoPagamentoSelecionadoSelect
  ] = useState<string>(dados?.planoPagamentoId)

  const [
    planoPagamentoSelecionado,
    definirPlanoPagamentoSelecionado
  ] = useState<PlanoPagamentoReingresso>(null)

  const ehPagamentoRecorrente = !!(
    formaPagamento === FormaPagamentoCobranca.CartaoCredito
  )

  const opcoesPagamento = [
    {
      id: FormaPagamentoCobranca[FormaPagamentoCobranca.Boleto],
      texto: 'Boleto'
    },
    {
      id: FormaPagamentoCobranca[FormaPagamentoCobranca.CartaoCredito],
      texto: 'Cartão de crédito'
    }
  ]

  const alterarCampoForm = (campo: string, valor: any, scope?: string) => {
    const nomeCampo = scope ? `${scope}.${campo}` : campo
    formRef?.current?.setFieldError(nomeCampo, null)
    formRef?.current?.setFieldValue(nomeCampo, valor)
  }

  const dadosIniciais = useMemo(() => {
    if (dados?.planoPagamentoId) {
      return {
        formPagamentoParcela: dados?.formaPagamento ?? '',
        planoPagamentoId: dados?.planoPagamentoId,
        responsavelFinanceiro:
          responsavelFinanceiro?.possuiResponsavelFinanceiro &&
          !!usuarioResponsavelExistente
            ? {
                email: usuarioResponsavelExistente.email,
                tipo: usuarioResponsavelExistente.tipoUsuario,
                nacionalidade: usuarioResponsavelExistente.nacionalidade,
                cpfCnpj:
                  usuarioResponsavelExistente.cpf ??
                  usuarioResponsavelExistente.cnpj,
                documentoIdentidade:
                  usuarioResponsavelExistente.documentoIdentidade ?? '',
                inscricaoEstadual:
                  usuarioResponsavelExistente.inscricaoEstadual,
                inscricaoMunicipal:
                  usuarioResponsavelExistente.inscricaoMunicipal,
                celular: usuarioResponsavelExistente.celular,
                nome: usuarioResponsavelExistente.nome
              }
            : {}
      }
    }

    return {
      formPagamentoParcela: '',
      planoPagamentoId: '',
      diaVencimento: ''
    }
  }, [dados, responsavelFinanceiro, usuarioResponsavelExistente])

  const valorDesconto = useMemo(() => {
    return cupom?.valorDesconto ?? 0
  }, [cupom])

  const valorDescontoFormatado = useMemo(
    () => FuncoesMoeda.formatarMoeda(valorDesconto),
    [valorDesconto]
  )

  const valorTotalOriginal = useMemo(() => {
    return dados?.valorReingresso ?? 0
  }, [dados?.valorReingresso])

  const valorTotalOriginalFormatado = useMemo(() => {
    return FuncoesMoeda.formatarMoeda(valorTotalOriginal)
  }, [dados?.valorReingresso])

  const valorParcela = useMemo(() => {
    return planoPagamentoSelecionado?.valorParcelaFinal ?? 0
  }, [planoPagamentoSelecionado])

  const valorParcelaFormatado = useMemo(
    () => FuncoesMoeda.formatarMoeda(valorParcela),
    [valorParcela]
  )

  const valorTotal = useMemo(() => {
    let novoValor = valorTotalOriginal

    if (valorDesconto < novoValor) {
      novoValor -= valorDesconto
    } else {
      novoValor = 0
    }
    return novoValor
  }, [cupom, valorTotalOriginal])

  const valorTotalFormatado = useMemo(() => {
    return FuncoesMoeda.formatarMoeda(valorTotal)
  }, [valorTotal])

  const bloqueioCamposResponsavelFinanceiro = useMemo(
    () => carregando || !!usuarioResponsavelExistente,
    [carregando, usuarioResponsavelExistente]
  )

  const responsavelFinanceiroPf = useMemo(
    () => tipoUsuarioResponsavelFinanceiro === TipoUsuario.PF,
    [tipoUsuarioResponsavelFinanceiro]
  )

  const pfEstrangeiro = useMemo(
    () =>
      nacionalidadeResponsavelFinanceiro ===
        Nacionalidade[Nacionalidade.outra] && responsavelFinanceiroPf,
    [nacionalidadeResponsavelFinanceiro, responsavelFinanceiroPf]
  )

  const obterValorFormulario = (campo: string, scope?: string) => {
    const nomeCampo = scope ? `${scope}.${campo}` : campo

    return formRef?.current?.getFieldValue(nomeCampo)
  }

  const acaoSucesso = async (dados: PlanoPagamentoMatriculaForm) => {
    try {
      const possuiResponsavelFinanceiro =
        responsavelFinanceiro?.possuiResponsavelFinanceiro ?? false

      let responsavelFinanceiroPayload: ResponsavelFinanceiro = null

      if (possuiResponsavelFinanceiro) {
        const usuarioResponsavelForm = dados.responsavelFinanceiro
        const usuarioResponsavelFormPF =
          usuarioResponsavelForm.tipo === TipoUsuario.PF

        responsavelFinanceiroPayload = {
          possuiResponsavelFinanceiro,
          idUsuarioResponsavel: possuiResponsavelFinanceiro
            ? usuarioResponsavelExistente?.id
            : null,
          usuarioResponsavel:
            usuarioResponsavelForm && !usuarioResponsavelExistente?.id
              ? {
                  nome: usuarioResponsavelForm.nome,
                  email: usuarioResponsavelForm.email,
                  tipoUsuario: usuarioResponsavelForm.tipo,
                  cnpj: !usuarioResponsavelFormPF
                    ? usuarioResponsavelForm.cpfCnpj
                    : null,
                  cpf: usuarioResponsavelFormPF
                    ? usuarioResponsavelForm.cpfCnpj
                    : null,
                  nomeApresentacao: usuarioResponsavelForm.nome,
                  nacionalidade: usuarioResponsavelForm.nacionalidade,
                  situacao: UsuarioSituacao.Ativo,
                  senha: null,
                  perfis: [],
                  celular: usuarioResponsavelForm.celular,
                  documentoIdentidade: usuarioResponsavelFormPF
                    ? usuarioResponsavelForm.documentoIdentidade
                    : null,
                  inscricaoEstadual: !usuarioResponsavelFormPF
                    ? usuarioResponsavelForm.inscricaoEstadual
                    : null,
                  inscricaoMunicipal: !usuarioResponsavelFormPF
                    ? usuarioResponsavelForm.inscricaoMunicipal
                    : null,
                  endereco: enderecoResponsavelDiferente
                    ? {
                        ...usuarioResponsavelForm.endereco,
                        cep: usuarioResponsavelForm.endereco?.cep?.replaceAll(
                          '-',
                          ''
                        )
                      }
                    : null
                }
              : null,
          enderecoUsuarioMatricula: !enderecoResponsavelDiferente
        }
      }

      definirCarregandoAvancar(true)

      const responsavelEnviado = await Api.AvancarPlanoPagamentoReingresso(
        id,
        responsavelFinanceiroPayload,
        dados.planoPagamentoId,
        ehPagamentoRecorrente,
        cupom?.cupomId,
        formaPagamento
      )

      definirPlanoPagamento({
        ...dados,
        responsavelFinanceiro: null,
        valorParcelado: valorTotalOriginal,
        pagamentoRecorrente: ehPagamentoRecorrente
      })

      const novoEstadoResponsavelFinanceiro = responsavelEnviado ?? {
        possuiResponsavelFinanceiro: false
      }

      definirResponsavelFinanceiro(novoEstadoResponsavelFinanceiro)

      mudarEtapa(4)
      definirPasso(4)
    } finally {
      definirCarregandoAvancar(false)
    }

    if (process.env.REACT_APP_GTM) {
      const cursosGTM = []

      const cursoGTM = {
        id: v4(),
        name: curso.nome,
        category: curso.modalidade,
        price: curso.valorTaxaMatricula,
        position: cursosGTM?.length + 1,
        metric1: 0,
        metric2: curso.valorTaxaMatricula,
        metric3: dados.diaVencimento
      }

      cursosGTM.push(cursoGTM)

      const tagManagerArgs = {
        gtmId: 'GTM-KF5XH378',
        dataLayer: {
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: {
                id: v4(),
                option: dados.formPagamentoParcela,
                step: 'Plano de Pagamento',
                revenue: curso.valorTaxaMatricula
              },
              products: cursosGTM
            }
          }
        }
      }
      TagManager.initialize(tagManagerArgs)
    }
  }

  useEffect(() => {
    scrollParaTopo()
  }, [passo])

  const buscarPlanosPagamento = async (nome: string) => {
    if (!formaPagamento) return
    try {
      const dados = await Api.RequisitarPlanosPagamentoReingresso(
        id,
        formaPagamento,
        cupom?.cupomId
      )

      if (!nome) {
        definirListaPlanosPagamento(dados)
      }

      const dadosSelect = dados?.map(item => {
        return { id: item.id, texto: item.texto }
      })

      definirListaPlanoPagamentoSelect(dadosSelect ?? [])
      definirListaPlanoPagamento(dados)

      return dados
    } catch {
      definirListaPlanoPagamentoSelect([])
    }
  }

  const definirDadosResponsavel = (dadosUsuario: ObterUsuarioDTO) => {
    if (!dadosUsuario) return

    definirTipoUsuarioResponsavelFinanceiro(dadosUsuario?.tipoUsuario)

    const scope = 'responsavelFinanceiro'

    alterarCampoForm('tipo', dadosUsuario?.tipoUsuario, scope)
    if (dadosUsuario?.tipoUsuario === TipoUsuario.PF) {
      alterarCampoForm(
        'documentoIdentidade',
        dadosUsuario?.documentoIdentidade ?? '',
        scope
      )

      alterarCampoForm('nacionalidade', dadosUsuario?.nacionalidade, scope)
    } else {
      alterarCampoForm(
        'inscricaoEstadual',
        dadosUsuario?.inscricaoEstadual,
        scope
      )
      alterarCampoForm(
        'inscricaoMunicipal',
        dadosUsuario?.inscricaoMunicipal,
        scope
      )
    }

    alterarCampoForm('nome', dadosUsuario?.nome, scope)

    const cpfCnpj = dadosUsuario?.cpf ?? dadosUsuario?.cnpj

    alterarCampoForm('cpfCnpj', cpfCnpj, scope)
    alterarCampoForm('email', dadosUsuario?.email, scope)

    alterarCampoForm('celular', dadosUsuario?.celular, scope)
  }

  const verificarExistenciaPorEmail = async () => {
    const scope = 'responsavelFinanceiro'
    const email = obterValorFormulario('email', scope)

    if (usuarioResponsavelExistente?.email === email) return
    try {
      definirCarregando(true)
      const usuarioResponsavelFinanceiro = await Api.VerificarUsuarioResponsavel(
        email
      )

      if (!usuarioResponsavelFinanceiro) {
        definirUsuarioResponsavelExistente(null)

        if (!tipoUsuarioResponsavelFinanceiro) {
          definirTipoUsuarioResponsavelFinanceiro(TipoUsuario.PF)
          alterarCampoForm('tipo', TipoUsuario.PF, scope)
        }

        return
      }

      definirUsuarioResponsavelExistente(usuarioResponsavelFinanceiro)

      definirDadosResponsavel(usuarioResponsavelFinanceiro)
    } catch (error) {
      definirUsuarioResponsavelExistente(null)
    } finally {
      definirCarregando(false)
    }
  }

  const verificarExistencia = async () => {
    const scope = 'responsavelFinanceiro'
    const cpfCnpj = obterValorFormulario('cpfCnpj', scope)

    if (
      usuarioResponsavelExistente?.cpf === cpfCnpj ||
      usuarioResponsavelExistente?.cnpj === cpfCnpj
    ) {
      return
    }

    try {
      definirCarregando(true)
      const usuarioResponsavelFinanceiro = responsavelFinanceiroPf
        ? await Api.VerificarUsuarioResponsavel(cpfCnpj)
        : await Api.VerificarUsuarioResponsavelPJ(cpfCnpj)

      if (!usuarioResponsavelFinanceiro) {
        definirUsuarioResponsavelExistente(null)
        return
      }

      definirUsuarioResponsavelExistente(usuarioResponsavelFinanceiro)

      definirDadosResponsavel(usuarioResponsavelFinanceiro)
    } catch (error) {
      definirUsuarioResponsavelExistente(null)
    } finally {
      definirCarregando(false)
    }
  }

  const buscarCep = async (cep: string) => {
    if (paisEndereco !== PaisResidencia.Brasil) {
      return
    }

    const endereco = await Api.BuscarCep(cep)
    const scope = 'responsavelFinanceiro.endereco'
    if (endereco) {
      alterarCampoForm('bairro', endereco.bairro, scope)
      alterarCampoForm('logradouro', endereco.logradouro, scope)
      alterarCampoForm('cidade', endereco.cidade, scope)
      alterarCampoForm('uf', endereco.uf, scope)
      alterarCampoForm('bairro', endereco.bairro, scope)
      alterarCampoForm('numero', endereco.numero, scope)
      alterarCampoForm('bairro', endereco.bairro, scope)

      document.getElementById('ipt_numero')?.focus()
    } else {
      alterarCampoForm('bairro', '', scope)
      alterarCampoForm('logradouro', '', scope)
      alterarCampoForm('cidade', '', scope)
      alterarCampoForm('uf', '', scope)
      alterarCampoForm('complemento', '', scope)
      alterarCampoForm('numero', '', scope)
    }
  }

  const validarCupom = async ({ codigoCupom }: { codigoCupom?: string }) => {
    if (codigoCupom) {
      definirCodigoCupom(codigoCupom)
      const novoCupom = await Api.RequisitarValidarCupomReingresso(
        id,
        codigoCupom,
        formaPagamento
      )

      if (novoCupom) {
        definirCupom({ ...novoCupom, codigoCupom })
      } else {
        definirCupom({} as CupomValido)
      }

      formCupomRef.current?.setFieldError(
        'codigoCupom',
        novoCupom ? 'Cupom válido' : 'Cupom inválido'
      )
    } else {
      definirCupom({} as CupomValido)
      formCupomRef.current?.setFieldError('codigoCupom', '')
    }
  }

  const schemaValido = useMemo(() => {
    if (
      responsavelFinanceiro?.possuiResponsavelFinanceiro &&
      !usuarioResponsavelExistente
    ) {
      return valorTotalOriginal > 0
        ? schemaPosGraduacaoPlanoPagamentoNovoResponsavelFinanceiro
        : schemaResponsavelFinanceiroPF
    }

    return valorTotalOriginal > 0 ? schemaPosGraduacaoPlanoPagamento : undefined
  }, [valorTotalOriginal, usuarioResponsavelExistente, responsavelFinanceiro])

  useEffect(() => {
    if (dados?.planoPagamentoId && listaPlanoPagamentoSelect?.length) {
      alterarCampoForm('planoPagamentoId', dados?.planoPagamentoId, null)
      return
    }
    alterarCampoForm('planoPagamentoId', null)
  }, [dados?.planoPagamentoId, listaPlanoPagamentoSelect])

  const setFormaPagamentoEdicao = async selecionado => {
    if (!selecionado) {
      await definirFormaPagamento(null)
    } else {
      const { id } = selecionado
      await definirFormaPagamento(FormaPagamentoCobranca[id])
    }
    const { id } = selecionado
    if (FormaPagamentoCobranca[id] === FormaPagamentoCobranca.Boleto) {
      alterarCampoForm('diaVencimento', '0')
    } else if (
      FormaPagamentoCobranca[id] === FormaPagamentoCobranca.CartaoCredito
    ) {
      alterarCampoForm('diaVencimento', null)
    }
  }

  useEffect(() => {
    definirPlanoPagamentoSelecionadoSelect(null)
    definirPlanoPagamentoSelecionado(null)
    buscarPlanosPagamento(null)
    alterarCampoForm('diaVencimento', null)
  }, [formaPagamento])

  const retornarDataMaisTrintaDias = () => {
    const outraData = new Date()
    outraData.setDate(outraData.getDate() + 30)
    return `${dataFormatadaComBarras(outraData)}`
  }

  useEffect(() => {
    if (
      temCupom &&
      (formaPagamento === FormaPagamentoCobranca.Boleto ||
        (formaPagamento === FormaPagamentoCobranca.CartaoCredito &&
          listaPlanoPagamentoSelect.length > 0 &&
          planoPagamentoSelecionado))
    ) {
      validarCupom({ codigoCupom })
    }
  }, [formaPagamento, planoPagamentoSelecionado, listaPlanoPagamentoSelect])

  return (
    <ContainerConteudo>
      <h2>Resumo do Reingresso no Curso</h2>
      <ResumoCurso curso={({ ...dados?.curso } as unknown) as Curso} />
      <ContainerCadastro>
        <FormUnform
          ref={formRef}
          schema={schemaValido}
          dadosIniciais={dadosIniciais}
          acaoSucesso={acaoSucesso}
        >
          <h4>Plano de Pagamento do Reingresso no Curso</h4>

          <ContainerValores>
            <ContainerInfosPagamento>
              <ContainerInfos>
                <TituloInfo>Valores do Reingresso</TituloInfo>
                <Info>
                  <p>
                    Valor do Reingresso{' '}
                    <sup>
                      {dados.matriculaOrigem.opcaoTCC !== OpcaoTCC.Sim
                        ? '1'
                        : '1 2'}
                    </sup>
                  </p>
                  <p>{valorTotalOriginalFormatado}</p>
                </Info>
                <Info>
                  <p>
                    Cupom de Desconto ( - ){' '}
                    <sup>
                      {dados.matriculaOrigem.opcaoTCC !== OpcaoTCC.Sim
                        ? '2'
                        : '3'}
                      3
                    </sup>
                  </p>
                  <p>{valorDescontoFormatado}</p>
                </Info>
                <Info negrito>
                  <p>
                    Total Parcelado{' '}
                    <sup>
                      {dados.matriculaOrigem.opcaoTCC !== OpcaoTCC.Sim
                        ? '1'
                        : '1 2'}
                    </sup>
                  </p>
                  <p>{valorTotalFormatado}</p>
                </Info>
                <Info negrito>
                  <p>Valor da Parcela</p>
                  <p>{valorParcelaFormatado}</p>
                </Info>
              </ContainerInfos>
              <ContainerInfos>
                {dados.matriculaOrigem.opcaoTCC !== OpcaoTCC.Sim && (
                  <Info>
                    <Informacao
                      invertido
                      id="informacao-tcc-nao-incluso"
                      textoTooltip="O TCC é opcional. Para realizar, o aluno deve informar ao núcleo acadêmico após ingresso no curso, e será cobrada uma taxa adicional de R$ 500,00 (parcelada em até 3x) no início das orientações do mesmo."
                    >
                      <sup>1</sup> TCC não incluso
                    </Informacao>
                  </Info>
                )}
                <Info>
                  <Informacao
                    invertido
                    id="informacao-valor-taxa-matricula"
                    textoTooltip="O valor da taxa de matrícula não está vinculado ao total do curso, pois refere-se a encargos administrativos. A confirmação do mesmo, garante sua vaga no curso. O pagamento da taxa de matrícula será realizado na última etapa deste processo."
                  >
                    <sup>
                      {dados.matriculaOrigem.opcaoTCC !== OpcaoTCC.Sim ? 2 : 1}
                    </sup>{' '}
                    Valor da taxa de matrícula não incluso
                  </Informacao>
                </Info>
                <Info>
                  <Informacao
                    invertido
                    id="informacao-cartao-recorrencia"
                    semIcone
                  >
                    <sup>
                      {dados.matriculaOrigem.opcaoTCC !== OpcaoTCC.Sim ? 3 : 2}
                    </sup>{' '}
                    Benefício: Opte pelo pagamento em Cartão de Crédito
                    recorrente, onde não consome seu limite total, e garanta
                    taxa de matrícula isenta. Para isso, basta inserir os dados
                    do cartão na última etapa da matrícula.
                  </Informacao>
                </Info>
              </ContainerInfos>
            </ContainerInfosPagamento>
          </ContainerValores>
          {valorTotalOriginal <= 0 && (
            <TituloIsento>ISENTO: Não há parcelas a pagar.</TituloIsento>
          )}
          <ContainerOpcoes>
            <Linha>
              <ContainerMedio>
                <SelectUnform
                  id="slct_formPagamentoParcela"
                  name="formPagamentoParcela"
                  label={
                    <>
                      Forma de Pagamento <sup>3</sup>
                      <IconeLabel
                        data-tip="Nesta forma de pagamento, mensalmente você deve entrar em seu portal e realizar o pagamento via boleto ou cartão de crédito da parcela do mês vigente."
                        data-for="tooltip-forma-pagamento"
                      >
                        {IconeInformacao}
                      </IconeLabel>
                    </>
                  }
                  placeholder="Selecione"
                  valorAlterado={setFormaPagamentoEdicao}
                  obrigatorio
                  opcoes={opcoesPagamento}
                />
                <Tooltip
                  id="tooltip-forma-pagamento"
                  place="right"
                  className="tooltip-ipgs"
                  textColor={Cores.PRETO}
                  backgroundColor={Cores.CINZA_1_CLARO}
                />
              </ContainerMedio>
              {formaPagamento === FormaPagamentoCobranca.Boleto && (
                <ContainerMedio>
                  <SelectUnform
                    id="slc_dia_vencimento"
                    name="diaVencimento"
                    label="Dia de Vencimento da Parcela"
                    placeholder={retornarDataMaisTrintaDias()}
                    obrigatorio={false}
                    disabled={true}
                    margemInferior={false}
                    opcoes={[
                      {
                        id: '0',
                        texto: retornarDataMaisTrintaDias()
                      }
                    ]}
                  />
                </ContainerMedio>
              )}
              {formaPagamento === FormaPagamentoCobranca.CartaoCredito && (
                <ContainerMedio style={{ alignSelf: 'center' }}>
                  <OpcaoDados>
                    <Informacao
                      invertido
                      id="informacao-pagamento-recorrente"
                      textoTooltip="Nesta forma de pagamento, você irá cadastrar o seu cartão de crédito na nossa plataforma parceira CIELO e o pagamento será descontado mensalmente de forma automática, comprometendo apenas o limite da parcela do mês vigente."
                    >
                      <CheckboxUnform
                        id="chk_pagamento_recorrente"
                        name="chkPagamentoRecorrente"
                        texto="Pagamento Recorrente"
                        checked={ehPagamentoRecorrente}
                      ></CheckboxUnform>
                    </Informacao>
                  </OpcaoDados>
                </ContainerMedio>
              )}
            </Linha>
            <Linha>
              <ContainerMedio>
                <SelectUnform
                  key={'slc_plano_pagamento'}
                  id="slc_plano_pagamento"
                  name="planoPagamentoId"
                  label="Selecione o Plano de Pagamento do Reingresso"
                  obrigatorio
                  opcoes={listaPlanoPagamentoSelect}
                  disabled={!formaPagamento}
                  valorAlterado={valor => {
                    definirPlanoPagamentoSelecionadoSelect(valor?.id)
                    if (listaPlanosPagamento?.length > 0) {
                      if (valor) {
                        const opcaoSelecionada = listaPlanosPagamento.find(
                          item => item.id === valor?.id
                        )
                        definirPlanoPagamentoSelecionado(opcaoSelecionada)
                      } else {
                        definirPlanoPagamentoSelecionado(null)
                      }
                    }
                  }}
                />
              </ContainerMedio>
            </Linha>
            <Linha>
              {valorTotalOriginal > 0 && (
                <ContainerCupom>
                  <Checkbox
                    texto="Tenho um Cupom"
                    htmlFor="chk_tem_cupom"
                    tamanho="L"
                  >
                    <input
                      type="checkbox"
                      id="chk_tem_cupom"
                      checked={temCupom}
                      onChange={() => {
                        definirTemCupom(old => !old)
                        definirCupom({} as CupomValido)
                      }}
                    />
                  </Checkbox>
                  {temCupom ? (
                    <ContainerInputCupom>
                      <FormUnform
                        dadosIniciais={{
                          codigoCupom: cupom?.codigoCupom || ''
                        }}
                        ref={formCupomRef}
                        acaoSucesso={validarCupom}
                        acaoFalha={focarPrimeiroCampoComErro}
                      >
                        <CupomContainer>
                          <InputUnform
                            id="ipt_cupom"
                            name="codigoCupom"
                            sucesso={!!cupom.cupomId}
                            onBlur={event => {
                              definirCodigoCupom(event.target.value)
                              formCupomRef.current?.submitForm()
                            }}
                            placeholder="Código do cupom"
                          />
                          <Botao
                            id="btn_validar_cupom"
                            type="button"
                            onClick={() => {
                              formCupomRef.current?.submitForm()
                            }}
                            texto="Validar"
                          />
                        </CupomContainer>
                      </FormUnform>
                    </ContainerInputCupom>
                  ) : (
                    <></>
                  )}
                </ContainerCupom>
              )}
            </Linha>
            <Scope path="responsavelFinanceiro">
              <Linha>
                <ContainerPossuiResponsavelFinanceiro>
                  <Checkbox
                    texto="Possui Responsável Financeiro"
                    htmlFor="chk_possui_responsavel_financeiro"
                    tamanho="L"
                  >
                    <input
                      type="checkbox"
                      id="chk_possui_responsavel_financeiro"
                      checked={
                        responsavelFinanceiro?.possuiResponsavelFinanceiro ??
                        false
                      }
                      onChange={() => {
                        definirResponsavelFinanceiroLocal(old => {
                          const possui = !old.possuiResponsavelFinanceiro
                          if (!possui) {
                            definirUsuarioResponsavelExistente(null)
                          }
                          return {
                            ...old,
                            possuiResponsavelFinanceiro: possui,
                            usuarioResponsavel: !possui
                              ? old.usuarioResponsavel
                              : null
                          }
                        })
                      }}
                    />
                  </Checkbox>
                </ContainerPossuiResponsavelFinanceiro>
              </Linha>

              {responsavelFinanceiro?.possuiResponsavelFinanceiro && (
                <>
                  <Linha>
                    <ContainerMedio>
                      <InputUnform
                        id="ipt_email_responsavel_financeiro"
                        name="email"
                        label="E-mail"
                        obrigatorio
                        disabled={bloqueioCamposResponsavelFinanceiro}
                        onBlur={verificarExistenciaPorEmail}
                      />
                    </ContainerMedio>
                    <ContainerMedio>
                      <SelectUnform
                        id="slc_tipo_responsavel_financeiro"
                        name="tipo"
                        opcoes={SELECT_TIPO_USUARIO}
                        label={'Tipo Responsável Financeiro'}
                        disabled={bloqueioCamposResponsavelFinanceiro}
                        valorAlterado={valor => {
                          if (valor) {
                            definirTipoUsuarioResponsavelFinanceiro(
                              TipoUsuario[valor.id]
                            )
                          } else {
                            definirTipoUsuarioResponsavelFinanceiro(null)
                          }
                        }}
                      />
                    </ContainerMedio>
                  </Linha>
                  {tipoUsuarioResponsavelFinanceiro && (
                    <>
                      <>
                        <Linha>
                          {responsavelFinanceiroPf && (
                            <ContainerPequeno>
                              <SelectUnform
                                id="slc_nacionalidade_responsavel"
                                name="nacionalidade"
                                label="Nacionalidade"
                                placeholder="Selecione"
                                obrigatorio={
                                  !bloqueioCamposResponsavelFinanceiro
                                }
                                opcoes={SELECT_NACIONALIDADES}
                                disabled={bloqueioCamposResponsavelFinanceiro}
                                valorAlterado={valor => {
                                  definirNacionalidadeResponsavelFinanceiro(
                                    valor?.id
                                  )
                                }}
                              />
                            </ContainerPequeno>
                          )}
                          {!pfEstrangeiro && (
                            <ContainerPequeno>
                              <CpfCnpjInputUnform
                                id="ipt_cpf_responsavel_financeiro"
                                name="cpfCnpj"
                                label={responsavelFinanceiroPf ? 'CPF' : 'CNPJ'}
                                obrigatorio={
                                  !bloqueioCamposResponsavelFinanceiro
                                }
                                disabled={bloqueioCamposResponsavelFinanceiro}
                                onBlur={verificarExistencia}
                              />
                            </ContainerPequeno>
                          )}

                          {responsavelFinanceiroPf ? (
                            <ContainerPequeno>
                              <InputUnform
                                id="ipt_documento_identidade"
                                name="documentoIdentidade"
                                label="Documento de Identidade"
                                obrigatorio={
                                  !bloqueioCamposResponsavelFinanceiro
                                }
                                disabled={bloqueioCamposResponsavelFinanceiro}
                                maxLength={20}
                                bloquearCaracteresEspeciais
                              />
                            </ContainerPequeno>
                          ) : (
                            <>
                              <ContainerPequeno>
                                <InputUnform
                                  id="ipt_inscricao_estadual"
                                  name="inscricaoEstadual"
                                  label="Inscrição Estadual"
                                  obrigatorio={
                                    !bloqueioCamposResponsavelFinanceiro
                                  }
                                  disabled={bloqueioCamposResponsavelFinanceiro}
                                  maxLength={20}
                                />
                              </ContainerPequeno>
                              <ContainerPequeno>
                                <InputUnform
                                  id="ipt_inscricao_municipal"
                                  name="inscricaoMunicipal"
                                  label="Inscrição Municipal"
                                  disabled={bloqueioCamposResponsavelFinanceiro}
                                  maxLength={20}
                                />
                              </ContainerPequeno>
                            </>
                          )}
                          <ContainerPequeno>
                            <TelefoneInputUnform
                              id="ipt_celular_responsavel_financeiro"
                              name="celular"
                              label="Celular"
                              obrigatorio={!bloqueioCamposResponsavelFinanceiro}
                              disabled={bloqueioCamposResponsavelFinanceiro}
                            />
                          </ContainerPequeno>
                        </Linha>
                      </>

                      <Linha>
                        <ContainerCheio>
                          <InputUnform
                            id="ipt_nome_responsavel_financeiro"
                            name="nome"
                            label={
                              responsavelFinanceiroPf
                                ? 'Nome Completo'
                                : 'Razão Social'
                            }
                            obrigatorio={!bloqueioCamposResponsavelFinanceiro}
                            disabled={bloqueioCamposResponsavelFinanceiro}
                            maxLength={200}
                            style={{ textTransform: 'uppercase' }}
                          ></InputUnform>
                        </ContainerCheio>
                      </Linha>
                      {!usuarioResponsavelExistente && (
                        <>
                          <Linha>
                            <ContainerPossuiResponsavelFinanceiro>
                              <CheckboxUnform
                                id="chk_reaproveitar_endereco"
                                texto="O endereço do Responsável Financeiro é diferente do meu endereço"
                                tamanho="L"
                                name="enderecoDiferente"
                                onChange={() => {
                                  definirEnderecoResponsavelDiferente(
                                    old => !old
                                  )
                                }}
                                checked={enderecoResponsavelDiferente}
                              ></CheckboxUnform>
                            </ContainerPossuiResponsavelFinanceiro>
                          </Linha>
                          {enderecoResponsavelDiferente && (
                            <Scope path="endereco">
                              <Linha>
                                <>
                                  <ContainerPequeno>
                                    <SelectUnform
                                      id="slc_pais"
                                      name="pais"
                                      label="País"
                                      placeholder="Selecione"
                                      obrigatorio
                                      opcoes={SELECT_PAISES}
                                      valorAlterado={opcao =>
                                        definirPaisEndereco(
                                          opcao ? opcao.id : ''
                                        )
                                      }
                                    />
                                  </ContainerPequeno>
                                </>
                                <ContainerPequeno>
                                  <InputUnform
                                    id="ipt_cep"
                                    name="cep"
                                    label="CEP"
                                    obrigatorio={
                                      paisEndereco === PaisResidencia.Brasil
                                    }
                                    mascara="99999-999"
                                    onBlur={event =>
                                      buscarCep(event.target.value)
                                    }
                                  />
                                </ContainerPequeno>
                              </Linha>
                              <Linha>
                                <ContainerGrande>
                                  <InputUnform
                                    id="ipt_logradouro"
                                    name="logradouro"
                                    label="Logradouro"
                                    obrigatorio
                                    maxLength={200}
                                  />
                                </ContainerGrande>
                                <ContainerPequeno>
                                  <InputUnform
                                    id="ipt_numero"
                                    name="numero"
                                    label="Número"
                                    obrigatorio
                                    maxLength={10}
                                  />
                                </ContainerPequeno>
                              </Linha>
                              <Linha>
                                <ContainerMedio>
                                  <InputUnform
                                    id="ipt_complemento"
                                    name="complemento"
                                    label="Complemento"
                                    maxLength={200}
                                  />
                                </ContainerMedio>
                                <ContainerMedio>
                                  <InputUnform
                                    id="ipt_bairro"
                                    name="bairro"
                                    label="Bairro"
                                    obrigatorio
                                    maxLength={200}
                                  />
                                </ContainerMedio>
                              </Linha>
                              <Linha>
                                <ContainerMedio>
                                  <InputUnform
                                    id="ipt_cidade"
                                    name="cidade"
                                    label="Cidade"
                                    obrigatorio
                                    maxLength={200}
                                  />
                                </ContainerMedio>
                                <ContainerMedio>
                                  <InputUnform
                                    id="ipt_uf"
                                    name="uf"
                                    label="UF"
                                    obrigatorio
                                  />
                                </ContainerMedio>
                              </Linha>
                            </Scope>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Scope>
          </ContainerOpcoes>
          <ContainerAcoes>
            <BotaoStep
              invertido
              texto="Voltar para"
              textoNegrito="Informações do reingresso"
              tema="Secundario"
              type="button"
              disabled={carregandoVoltar || !!dados?.matriculaDestino}
              onClick={() => {
                definirCarregandoVoltar(true)
                voltarWizard()
              }}
            />
            <BotaoStep
              texto="Ir para"
              textoNegrito="Termos e Condições"
              carregando={carregandoAvancar}
              type="submit"
            />
          </ContainerAcoes>
        </FormUnform>
      </ContainerCadastro>
    </ContainerConteudo>
  )
}
