import { saveAs } from 'file-saver'
import queryString from 'query-string'
import { nomeArquivoContentDisposition, SelectOpcao } from 'src/componentes'
import { PesquisaTurmaDados, PesquisaTurmaDTO } from 'src/dto'
import { Dados } from 'src/paginas/ipgs/turmas/notas-presenca/alunos/componentes/tipos'
import { AlunosPresencial } from 'src/paginas/ipgs/turmas/notas-presenca/tipos'
import { InformacoesModulosFlex } from 'src/paginas/sala-aula/selecao-modulos-flex/tipos'
import {
  Turma,
  TurmaSituacao,
  OpcoesPaginacao,
  TipoOrdenacao,
  Material,
  RespostaPadraoAPI,
  DadosAlunoFinalizacao,
  AtualizarProfessorTCC,
  EnviarEmailProfessorTcc,
  FeedbackTCC,
  EnviarModuloFlexiveis,
  NovosModuloFlexiveis,
  TurmaSplit,
  TurmaSplitItem
} from 'src/tipos'

import { gatewayApi } from '../instancias-api'
import { materiaisParaFormData } from './comum'
import {
  TurmaEadAvaliacaoModuloPayload,
  TurmaAvaliacaoModuloResposta,
  TurmaEadAvaliacaoDisciplinaPayload,
  TurmaAvaliacaoDisciplinaResposta,
  TurmaPresencialAvaliacaoModuloPayload,
  TurmaPresencialAvaliacaoDisciplinaPayload,
  CriarCopiaTurmaResposta
} from './tipos'

const urlBase = '/turma'

export const RequisitarListaTurmas = async (
  nome?: string,
  codigo?: string,
  uf?: string,
  cidade?: string,
  situacao?: keyof typeof TurmaSituacao,
  pagina?: number,
  qtdRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: PesquisaTurmaDTO[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const filtros = queryString.stringify({
      nome,
      codigo,
      uf,
      cidade,
      situacao:
        situacao === TurmaSituacao[TurmaSituacao.Todos] ? null : situacao,
      qtdRegistros,
      pagina,
      ordem
    })

    const {
      data: { itens, quantidade }
    } = await gatewayApi.get<PesquisaTurmaDados>(`${urlBase}?${filtros}`)

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: qtdRegistros,
      totalRegistros: quantidade,
      totalPaginas: Math.ceil(quantidade / qtdRegistros)
    }

    return { registros: itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const RequisitarListaTurmasPorTexto = async (
  texto: string
): Promise<SelectOpcao[]> => {
  try {
    const filtros = queryString.stringify({
      nome: texto,
      qtdRegistros: 100
    })

    const {
      data: { itens }
    } = await gatewayApi.get<PesquisaTurmaDados>(`${urlBase}?${filtros}`)

    return itens.map(response => ({
      id: response.id,
      texto: `${response.codigo} - ${response.nome}`,
      dadosAdicionais: response
    }))
  } catch {
    return null
  }
}

export const RequisitarTurmaPorId = async (id: string): Promise<Turma> => {
  const { data } = await gatewayApi.get<Turma>(`${urlBase}/${id}`)

  return Object.assign({}, data ?? {}, { id: id }) as Turma
}

export const CriarTurmaEadAvaliacaoModulo = async (
  payload: TurmaEadAvaliacaoModuloPayload
): Promise<TurmaAvaliacaoModuloResposta> => {
  const { data } = await gatewayApi.post<TurmaAvaliacaoModuloResposta>(
    `${urlBase}/ead/avaliacao-modulo/criar`,
    payload
  )

  return data
}

export const EditarTurmaEadAvaliacaoModulo = async (
  idTurma: string,
  payload: TurmaEadAvaliacaoModuloPayload
): Promise<TurmaAvaliacaoModuloResposta> => {
  const { data } = await gatewayApi.put<TurmaAvaliacaoModuloResposta>(
    `${urlBase}/${idTurma}/ead/avaliacao-modulo/editar`,
    payload
  )

  return data
}

export const CriarTurmaEadAvaliacaoDisciplina = async (
  payload: TurmaEadAvaliacaoDisciplinaPayload
): Promise<TurmaAvaliacaoDisciplinaResposta> => {
  const { data } = await gatewayApi.post<TurmaAvaliacaoDisciplinaResposta>(
    `${urlBase}/ead/avaliacao-disciplina/criar`,
    payload
  )

  return data
}

export const EditarTurmaEadAvaliacaoDisciplina = async (
  idTurma: string,
  payload: TurmaEadAvaliacaoDisciplinaPayload
): Promise<TurmaAvaliacaoDisciplinaResposta> => {
  const { data } = await gatewayApi.put<TurmaAvaliacaoDisciplinaResposta>(
    `${urlBase}/${idTurma}/ead/avaliacao-disciplina/editar`,
    payload
  )

  return data
}

export const CriarAvaliacaoArquivoTurma = async (
  idTurma: string,
  idAvaliacao: string,
  bodyFormData: FormData
): Promise<void> => {
  await gatewayApi.post(
    `${urlBase}/${idTurma}/avaliacao/${idAvaliacao}/criar`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const CriarMaterialTurma = async (
  idTurma: string,
  materiais: Material[]
): Promise<void> => {
  const bodyFormData = materiaisParaFormData(materiais)

  await gatewayApi.post(
    `${urlBase}/${idTurma}/criar-materiais-turma`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const EditarMaterialTurma = async (
  idTurma: string,
  materiais: Material[]
): Promise<void> => {
  const bodyFormData = materiaisParaFormData(materiais)

  await gatewayApi.put(
    `${urlBase}/${idTurma}/editar-materiais-turma`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const CriarMaterialDisciplinaTurma = async (
  idTurma: string,
  idModulo: string,
  idDisciplina: string,
  materiais: Material[]
): Promise<void> => {
  const bodyFormData = materiaisParaFormData(materiais)

  await gatewayApi.post(
    `${urlBase}/${idTurma}/modulo/${idModulo}/disciplina/${idDisciplina}/criar-materiais`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const EditarMaterialDisciplinaTurma = async (
  idTurma: string,
  idModulo: string,
  idDisciplina: string,
  materiais: Material[]
): Promise<void> => {
  const bodyFormData = materiaisParaFormData(materiais)

  await gatewayApi.put(
    `${urlBase}/${idTurma}/modulo/${idModulo}/disciplina/${idDisciplina}/editar-materiais`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const CriarTurmaPresencialAvaliacaoModulo = async (
  payload: TurmaPresencialAvaliacaoModuloPayload
): Promise<TurmaAvaliacaoModuloResposta> => {
  const { data } = await gatewayApi.post<TurmaAvaliacaoModuloResposta>(
    `${urlBase}/presencial/avaliacao-modulo/criar`,
    payload
  )

  return data
}

export const ObterDetalhesTurmaPresencial = async (
  id: string
): Promise<Turma> => {
  const { data } = await gatewayApi.get<Turma>(
    `${urlBase}/presencial/obter-detalhes/${id}`
  )

  return data
}

export const EditarTurmaPresencialAvaliacaoModulo = async (
  idTurma: string,
  payload: TurmaPresencialAvaliacaoModuloPayload
): Promise<TurmaAvaliacaoModuloResposta> => {
  const { data } = await gatewayApi.put<TurmaAvaliacaoModuloResposta>(
    `${urlBase}/${idTurma}/presencial/avaliacao-modulo/editar`,
    payload
  )

  return data
}

export const CriarTurmaPresencialAvaliacaoDisciplina = async (
  payload: TurmaPresencialAvaliacaoDisciplinaPayload
): Promise<TurmaAvaliacaoDisciplinaResposta> => {
  const { data } = await gatewayApi.post<TurmaAvaliacaoDisciplinaResposta>(
    `${urlBase}/presencial/avaliacao-disciplina/criar`,
    payload
  )

  return data
}

export const ObterDetalhesDisciplinaPresencial = async (
  idDaDisciplina: string,
  idTurma: string
): Promise<AlunosPresencial> => {
  const { data } = await gatewayApi.get<AlunosPresencial>(
    `${urlBase}/presencial/obter-detalhes-disciplina/${idTurma}/${idDaDisciplina}`
  )

  return data
}

export const ObterDetalhesDisciplinaTCCPresencial = async (
  idDaDisciplina: string,
  idTurma: string
): Promise<AlunosPresencial> => {
  const { data } = await gatewayApi.get<AlunosPresencial>(
    `${urlBase}/presencial/obter-detalhes-disciplina-tcc/${idTurma}/${idDaDisciplina}`
  )

  return data
}

export const EditarTurmaPresencialAvaliacaoDisciplina = async (
  idTurma: string,
  payload: TurmaPresencialAvaliacaoDisciplinaPayload
): Promise<TurmaAvaliacaoDisciplinaResposta> => {
  const { data } = await gatewayApi.put<TurmaAvaliacaoDisciplinaResposta>(
    `${urlBase}/${idTurma}/presencial/avaliacao-disciplina/editar`,
    payload
  )

  return data
}

export const CriarCopiaTurma = async (
  turmaId: string,
  codigo: string
): Promise<string> => {
  const { data } = await gatewayApi.post<CriarCopiaTurmaResposta>(
    `${urlBase}/copiar-turma`,
    {
      turmaId,
      codigo
    }
  )

  return data.turmaId
}
export const RegistrarFeedbackProfessor = async (
  salaId: string,
  payload: Dados
): Promise<boolean> => {
  const urlBaseFeedback = '/salaaulaead/curso-ead'
  if (payload.nota === null) {
    payload.nota = ''
  }
  if (payload.encontros === null) {
    payload.encontros = []
  }
  const {
    data: { success }
  } = await gatewayApi.post<RespostaPadraoAPI>(
    `${urlBaseFeedback}` + `/${salaId}` + '/registrar-feedback-professor', //eslint-disable-line
    payload
  )

  return success
}

export const RegistrarFeedbackTCC = async (
  salaId: string,
  payload: FeedbackTCC
): Promise<boolean> => {
  const urlBaseFeedback = '/salaaulaead/curso-ead'

  if (payload.notaOrientador === null) payload.notaOrientador = ''

  if (payload.notaBanca === null) payload.notaBanca = ''

  const {
    data: { success }
  } = await gatewayApi.post<RespostaPadraoAPI>(
    `${urlBaseFeedback}/${salaId}/registrar-feedback-tcc`,
    payload
  )

  return success
}

export const ObterDetalhesModulo = async (
  id: string,
  moduloId: string
): Promise<AlunosPresencial> => {
  const { data } = await gatewayApi.get<AlunosPresencial>(
    `${urlBase}/presencial/obter-detalhes-modulo/${id}/${moduloId}`
  )

  return data
}

export const DownloadUltimaAvaliacao = async (id: string): Promise<void> => {
  const resposta = await gatewayApi.get(
    `/salaAulaEad/curso-ead/${id}/avaliacao-por-arquivo/obter-ultima-avaliacao-enviada`,
    {
      responseType: 'blob'
    }
  )
  if (resposta?.status !== 200) return null
  if (resposta.data) {
    const nomeArquivo = nomeArquivoContentDisposition(
      resposta.headers['content-disposition']
    )
    saveAs(resposta.data, nomeArquivo)
  }
}

export const CriarMaterialDisciplinaTCCTurma = async (
  idTurma: string,
  idModulo: string,
  idDisciplina: string,
  materiais: Material[]
): Promise<void> => {
  const bodyFormData = materiaisParaFormData(materiais)

  await gatewayApi.post(
    `${urlBase}/${idTurma}/modulo-tcc/${idModulo}/disciplina/${idDisciplina}/criar-materiais`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const EditarMaterialDisciplinaTCCTurma = async (
  idTurma: string,
  idModulo: string,
  idDisciplina: string,
  materiais: Material[]
): Promise<void> => {
  const bodyFormData = materiaisParaFormData(materiais)

  await gatewayApi.put(
    `${urlBase}/${idTurma}/modulo-tcc/${idModulo}/disciplina/${idDisciplina}/editar-materiais`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const CriarMaterialComplementar = async (
  idTurma: string,
  materiais: Material[]
): Promise<void> => {
  const bodyFormData = materiaisParaFormData(materiais)

  await gatewayApi.post(
    `${urlBase}/${idTurma}/criar-materiais-complementares`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const EditarMaterialComplementar = async (
  idTurma: string,
  materiais: Material[]
): Promise<void> => {
  const bodyFormData = materiaisParaFormData(materiais)

  await gatewayApi.put(
    `${urlBase}/${idTurma}/editar-materiais-complementares`,
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const ObterDadosAlunoFinalizacao = async (
  idTurma: string
): Promise<DadosAlunoFinalizacao> => {
  const { data } = await gatewayApi.get<DadosAlunoFinalizacao>(
    `${urlBase}/${idTurma}/dados-alunos-finalizacao`
  )

  return data
}

export const AtualizarProfessoresTCC = async (
  payload: AtualizarProfessorTCC
): Promise<boolean> => {
  const resposta = await gatewayApi.put(
    `${urlBase}/atualizar-professores-tcc`,
    payload
  )

  return resposta.status === 200 || resposta.status === 204
}

export const EnviarEmailProfessoresTcc = async (
  payload: EnviarEmailProfessorTcc
): Promise<any> => {
  const resposta = await gatewayApi.post(
    `${urlBase}/enviar-email-professor-tcc`,
    payload
  )

  return resposta.status === 200 || resposta.status === 204
}

export const ObterModulosFlexiveis = async (
  idTurma: string
): Promise<InformacoesModulosFlex> => {
  const { data } = await gatewayApi.get<InformacoesModulosFlex>(
    `SalaAulaEad/curso-ead/${idTurma}/modulos-flex`
  )

  return data
}

export const EnviarModulosFlexiveis = async (
  modulosEscolhidos: EnviarModuloFlexiveis[],
  idTurma: string
): Promise<any> => {
  const resposta = await gatewayApi.post(
    `SalaAulaEad/curso-ead/${idTurma}/modulos-flex`,
    {
      modulosEscolhidos: modulosEscolhidos
    }
  )

  return resposta.status === 200 || resposta.status === 204
}

export const EditarModulosFlexiveis = async (
  novosModulosEscolhidos: NovosModuloFlexiveis,
  idTurma: string
): Promise<any> => {
  const resposta = await gatewayApi.put(
    `SalaAulaEad/curso-ead/${idTurma}/modulos-flex`,
    novosModulosEscolhidos
  )

  return resposta.status === 200 || resposta.status === 204
}

export const ObterDetalhesTurmaSplit = async (
  nome: string,
  cnpj: string,
  idBraspag: string,
  pagina?: number,
  quantidadeRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: Array<TurmaSplitItem>
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const filtros = queryString.stringify({
      nome,
      cnpj,
      idBraspag,
      quantidadeRegistros,
      pagina,
      ordem
    })

    const {
      data: { itens, quantidade }
    } = await gatewayApi.get<TurmaSplit>(
      `${urlBase}/parceiros-split?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: quantidadeRegistros,
      totalRegistros: quantidade,
      totalPaginas: Math.ceil(quantidade / quantidadeRegistros)
    }

    return new Promise(resolve =>
      resolve({ registros: itens, opcoesPaginacao })
    )
  } catch {
    return null
  }
}

export const InserirSplit = async (
  payload: TurmaSplitItem
): Promise<boolean> => {
  const {
    data: { success }
  } = await gatewayApi.post<RespostaPadraoAPI>(
    `${urlBase}/cadastrar-split`,
    payload
  )

  return success
}

export const EditarSplit = async (
  id: string,
  payload: TurmaSplitItem
): Promise<boolean> => {
  const {
    data: { success }
  } = await gatewayApi.put<RespostaPadraoAPI>(
    `${urlBase}/editar-split`,
    payload
  )

  return success
}

export const ObterDadosSplit = async (id: string): Promise<TurmaSplitItem> => {
  const { data } = await gatewayApi.get<TurmaSplitItem>(
    `${urlBase}/split/obter-detalhes/${id}`
  )

  return data
}
