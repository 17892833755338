import React, { useState, useEffect, useMemo, useRef } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Scope } from '@unform/core'
import { FormRef } from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { Breadcrumb } from 'src/componentes/breadcrumb'
import { Cabecalho } from 'src/componentes/cabecalho'
import { focarPrimeiroCampoComErro } from 'src/componentes/funcoes'
import { IconeBack, IconeLixeira } from 'src/componentes/icones'
import { Link } from 'src/componentes/link'
import { Modal } from 'src/componentes/modal'
import { ModalRef } from 'src/componentes/modal/tipos'
import { Navegacao } from 'src/componentes/navegacao'
import { SubTitulo } from 'src/componentes/subtitulo'
import {
  NumeroInputUnform,
  SelectOpcao,
  SeletorUnform,
  TelefoneInputUnform
} from 'src/componentes/unform'
import { FormUnform } from 'src/componentes/unform/form'
import { InputUnform } from 'src/componentes/unform/input'
import { CnpjInputUnform } from 'src/componentes/unform/input/cnpj'
import { SelectUnform, SelectAsyncUnform } from 'src/componentes/unform/select'
import { SELECT_PAISES } from 'src/dados-estaticos'
import { RotasAcademico } from 'src/rotas'
import { Api } from 'src/servicos'
import {
  RequisitarListaTurmasPorTexto,
  RequisitarTurmaPorId
} from 'src/servicos/api'
import {
  ModoPaginaCadastro,
  TurmaSplitItem,
  PaisResidencia,
  TipoConta
} from 'src/tipos'

import { schemaComCadastro, schemaSemCadastro } from './schema'
import {
  Container,
  ContainerMain,
  ContainerLink,
  Conteudo,
  ContainerAcoes,
  ContainerLateral,
  Linha,
  ContainerMedio,
  ContainerGrande,
  ContainerPequeno,
  Secao,
  LinhaOpt,
  Padding
} from './styles'
import { PaginaSplitProps } from './tipos'

enum Navegacoes {
  DadosParceiro = 'dados-parceiro',
  DadosRepasse = 'dados-repasse',
  DadosPagamento = 'dados-pagamento',
  DadosEndereco = 'dados-endereco'
}

const CadastroSplitPagamento: React.FC<PaginaSplitProps> = ({
  match,
  modo
}) => {
  const formRef = useRef<FormRef>(null)
  const modalRef = useRef<ModalRef>(null)
  const history = useHistory()
  const [titulo] = useState(
    `${
      modo === ModoPaginaCadastro.Inclusao ? 'Inclusão' : 'Edição'
    } de Split de Pagamento`
  )
  const [pronto, definirPronto] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const [temCadastro, definirTemCadastro] = useState(0)
  const [pais, definirPais] = useState<string>(PaisResidencia.Brasil)
  const [split, definirSplit] = useState<TurmaSplitItem | undefined>(undefined)

  const [classRepassList, setClassRepassList] = useState<
    Array<{ turmaId: string; repass: string }>
  >([{ turmaId: '', repass: '' }])

  const dadosIniciais = useMemo(() => {
    return split
      ? {
          ...split
        }
      : { jaTemCadastro: 0 }
  }, [split])

  const carregarSplit = async () => {
    try {
      if (modo === ModoPaginaCadastro.Edicao) {
        const novoSplit = await Api.ObterDadosSplit(match.params.id)

        setClassRepassList(
          novoSplit.turmaRepasse.map(() => ({ turmaId: '', repass: '' }))
        )

        definirSplit({
          ...novoSplit,
          repassInfo: novoSplit.turmaRepasse.map(item => ({
            turmaId: item.turmaId,
            repass: Number(item.repasse).toFixed(2)
          }))
        })
        definirTemCadastro(1)
      }
    } catch (error) {
      toast('Houve um problema ao obter os dados do split', {
        type: 'error'
      })
    } finally {
      definirPronto(true)
    }
  }

  const acaoSucesso = async (dados: TurmaSplitItem) => {
    try {
      definirCarregando(true)

      const dadosEnvio = {
        id: split?.id,
        nomeContato: dados.nomeContato,
        nomeFantasia: dados.nomeFantasia,
        agencia: dados.agencia,
        banco: dados.banco,
        cnpj: dados.cnpj,
        conta: dados.conta,
        email: dados.email,
        tipoConta: dados.tipoConta,
        endereco: {
          cep: dados.endereco.cep,
          logradouro: dados.endereco.logradouro,
          cidade: dados.endereco.cidade,
          uf: dados.endereco.uf,
          complemento: dados.endereco.complemento,
          numero: dados.endereco.numero,
          estado: dados.endereco.estado,
          bairro: dados.endereco.bairro,
          municipio: dados.endereco.municipio
        },
        turmaRepasse: dados.repassInfo.map(item => ({
          turmaId: item.turmaId,
          repasse: item.repass
        })),
        telefoneContato: dados.telefoneContato,
        ...(temCadastro === 1
          ? { merchantIdBraspag: dados.merchantIdBraspag }
          : {
              razaoSocial: dados.razaoSocial,
              digitoConta: dados.digitoConta,
              digitoAgencia: dados.digitoAgencia
            })
      }

      if (modo === ModoPaginaCadastro.Inclusao) {
        await Api.InserirSplit(dadosEnvio)
      } else {
        await Api.EditarSplit(match.params.id, dadosEnvio)
      }

      toast(
        `Split ${
          modo === ModoPaginaCadastro.Inclusao ? 'cadastrado' : 'editado'
        } com sucesso`,
        {
          type: 'success'
        }
      )

      history.push(RotasAcademico.SplitPagamento)
    } catch (erro) {
      toast('Ops! Aconteceu um erro. Tente novamente.', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const buscarCep = async (cep: string) => {
    if (pais !== PaisResidencia.Brasil) {
      return
    }

    const endereco = await Api.BuscarCep(cep)

    if (endereco) {
      formRef.current?.setFieldValue('endereco.bairro', endereco.bairro)
      formRef.current?.setFieldValue('endereco.logradouro', endereco.logradouro)
      formRef.current?.setFieldValue('endereco.cidade', endereco.cidade)
      formRef.current?.setFieldValue('endereco.uf', endereco.uf)
      formRef.current?.setFieldValue('endereco.numero', endereco.numero)
      document.getElementById('ipt_numero')?.focus()
    } else {
      formRef.current?.setFieldValue('endereco.bairro', '')
      formRef.current?.setFieldValue('endereco.logradouro', '')
      formRef.current?.setFieldValue('endereco.cidade', '')
      formRef.current?.setFieldValue('endereco.uf', '')
      formRef.current?.setFieldValue('endereco.complemento', '')
      formRef.current?.setFieldValue('endereco.numero', '')
    }
  }

  const carregarOpcoesBancos = async (filtro: string) => {
    try {
      const bancos = await Api.ObterBancosAtivos(filtro)
      return (
        bancos?.map(b => {
          return { id: b.id, texto: `${b.codigo ?? ' '} - ${b.nome}` }
        }) ?? []
      )
    } catch (erro) {
      toast('Erro ao carregar opções de bancos.', { type: 'error' })
    }
  }

  const carregarBancoPorId = async (id: string) => {
    try {
      const banco = await Api.ObterBancoPorId(id)
      return [{ id: banco.id, texto: `${banco.codigo ?? ' '} - ${banco.nome}` }]
    } catch (erro) {
      toast('Erro ao carregar opções de bancos.', { type: 'error' })
    }
  }

  const handleClick = () => {
    setClassRepassList([...classRepassList, { turmaId: '', repass: '' }])
  }

  const handleRemoveRepassList = (index: number) => {
    setClassRepassList(classRepassList.filter((_, idx) => idx !== index))
  }

  const carregarTurmaPorId = async (id: string): Promise<SelectOpcao[]> => {
    try {
      const dados = await RequisitarTurmaPorId(id)
      return [{ id: dados.id, texto: dados.nome }]
    } catch {
      return []
    }
  }

  const alterarSituacaoCadastro = valor => {
    definirTemCadastro(valor)
  }

  useEffect(() => {
    carregarSplit()
  }, [])

  return (
    <>
      {pronto ? (
        <Container>
          <ContainerMain>
            <ContainerLink>
              <Link
                texto="Voltar"
                href={RotasAcademico.SplitPagamento}
                icone={IconeBack}
              />
            </ContainerLink>
            <Cabecalho>
              <Breadcrumb
                titulo={titulo}
                atalhos={[
                  {
                    texto: 'Financeiro'
                  },
                  {
                    texto: 'Split Pagamento',
                    acao: () => history.goBack()
                  },
                  {
                    texto: titulo
                  }
                ]}
              />
            </Cabecalho>
            <Conteudo>
              <FormUnform
                ref={formRef}
                schema={
                  temCadastro === 1 ? schemaComCadastro : schemaSemCadastro
                }
                dadosIniciais={dadosIniciais}
                acaoSucesso={acaoSucesso}
                acaoFalha={focarPrimeiroCampoComErro}
              >
                {modo !== ModoPaginaCadastro.Edicao && (
                  <Secao>
                    <LinhaOpt>
                      <SubTitulo texto="O parceiro já está cadastrado na Braspag?" />
                      <SeletorUnform
                        name="jaTemCadastro"
                        tipo="radio"
                        seletores={[
                          {
                            id: 1,
                            texto: 'Sim'
                          },
                          {
                            id: 0,
                            texto: 'Não'
                          }
                        ]}
                        valorAlterado={alterarSituacaoCadastro}
                      />
                    </LinhaOpt>
                  </Secao>
                )}
                <Secao id={Navegacoes.DadosParceiro}>
                  <SubTitulo texto="Dados do Parceiro" />
                  <Linha>
                    <ContainerMedio>
                      <InputUnform
                        id="ipt_nome_contato"
                        name="nomeContato"
                        label="Nome do contato responsável"
                        obrigatorio
                      />
                    </ContainerMedio>
                    <ContainerMedio>
                      <TelefoneInputUnform
                        className="sm"
                        id="ipt_telefone_contato"
                        name="telefoneContato"
                        label="Telefone do contato responsável"
                        maxLength={11}
                        disabled={carregando}
                        obrigatorio
                      />
                    </ContainerMedio>
                  </Linha>
                  <Linha>
                    {temCadastro === 0 && modo !== ModoPaginaCadastro.Edicao && (
                      <ContainerMedio>
                        <InputUnform
                          id="ipt_razao_social"
                          name="razaoSocial"
                          label="Razão Social"
                          obrigatorio
                        />
                      </ContainerMedio>
                    )}
                    <ContainerMedio>
                      <InputUnform
                        id="ipt_nome_fantasia"
                        name="nomeFantasia"
                        label="Nome Fantasia"
                        obrigatorio
                      />
                    </ContainerMedio>
                  </Linha>
                  <Linha>
                    <ContainerMedio>
                      <InputUnform
                        className="md"
                        id="ipt_email"
                        type="email"
                        name="email"
                        label="E-mail"
                        maxLength={200}
                        disabled={carregando}
                        obrigatorio
                      />
                    </ContainerMedio>
                    <ContainerMedio>
                      <CnpjInputUnform
                        id="ipt_cnpj"
                        name="cnpj"
                        label="CNPJ"
                        obrigatorio
                      />
                    </ContainerMedio>
                  </Linha>
                </Secao>
                <Secao id={Navegacoes.DadosPagamento}>
                  <SubTitulo texto="Pagamento" />
                  <Linha>
                    <ContainerMedio>
                      <SelectAsyncUnform
                        id="ipt_banco_id"
                        name="banco"
                        label="Banco"
                        className="md ipt_banco_slc"
                        buscarPorTexto={carregarOpcoesBancos}
                        buscarPorId={carregarBancoPorId}
                        obrigatorio
                      />
                      <Padding />
                    </ContainerMedio>
                    <ContainerMedio>
                      <SelectUnform
                        className="md"
                        id="ipt_tipoConta"
                        name="tipoConta"
                        label="Tipo Conta"
                        placeholder="Selecione"
                        opcoes={[
                          {
                            id: `${TipoConta.ContaCorrente}`,
                            texto: 'Conta Corrente'
                          },
                          {
                            id: `${TipoConta.ContaPoupanca}`,
                            texto: 'Conta Poupança'
                          }
                        ]}
                        obrigatorio
                      />
                    </ContainerMedio>
                  </Linha>
                  <Linha>
                    <ContainerPequeno>
                      <InputUnform
                        className="sm"
                        id="ipt_agencia"
                        type="agencia"
                        name="agencia"
                        label="Agência"
                        obrigatorio
                      />
                    </ContainerPequeno>
                    {temCadastro === 0 && (
                      <ContainerPequeno>
                        <InputUnform
                          className="sm"
                          id="ipt_digito_agencia"
                          type="digitoAgencia"
                          name="digitoAgencia"
                          label="Digito da âgencia"
                          maxLength={4}
                          obrigatorio
                        />
                      </ContainerPequeno>
                    )}
                    <ContainerPequeno>
                      <InputUnform
                        className="sm"
                        id="ipt_conta"
                        type="conta"
                        name="conta"
                        label="Conta"
                        obrigatorio
                      />
                    </ContainerPequeno>
                    {temCadastro === 0 && (
                      <ContainerPequeno>
                        <InputUnform
                          className="sm"
                          id="ipt_digito_conta"
                          type="digitoConta"
                          name="digitoConta"
                          label="Digito da conta"
                          obrigatorio
                        />
                      </ContainerPequeno>
                    )}
                    {temCadastro === 1 && (
                      <ContainerMedio>
                        <InputUnform
                          className="md"
                          id="ipt_id_braspag"
                          name="merchantIdBraspag"
                          label="Id Loja Braspag"
                          disabled={modo === ModoPaginaCadastro.Edicao}
                          maxLength={50}
                          obrigatorio
                        />
                      </ContainerMedio>
                    )}
                  </Linha>
                </Secao>
                <Secao id={Navegacoes.DadosEndereco}>
                  <SubTitulo texto="Endereço" />
                  <Scope path="endereco">
                    <Linha>
                      <ContainerMedio>
                        <SelectUnform
                          id="slc_pais"
                          name="pais"
                          label="País"
                          placeholder="Selecione"
                          obrigatorio
                          opcoes={SELECT_PAISES}
                          valorAlterado={opcao =>
                            definirPais(opcao ? opcao.id : '')
                          }
                        />
                      </ContainerMedio>
                      <ContainerMedio>
                        <InputUnform
                          id="ipt_cep"
                          name="cep"
                          label="CEP"
                          obrigatorio={pais === PaisResidencia.Brasil}
                          mascara="99999-999"
                          onBlur={event => buscarCep(event.target.value)}
                        />
                      </ContainerMedio>
                    </Linha>
                    <Linha>
                      <ContainerGrande>
                        <InputUnform
                          id="ipt_logradouro"
                          name="logradouro"
                          label="Logradouro"
                          obrigatorio
                          maxLength={200}
                        />
                      </ContainerGrande>
                      <ContainerPequeno>
                        <InputUnform
                          id="ipt_numero"
                          name="numero"
                          label="Número"
                          obrigatorio
                          maxLength={10}
                        />
                      </ContainerPequeno>
                    </Linha>
                    <Linha>
                      <ContainerMedio>
                        <InputUnform
                          id="ipt_complemento"
                          name="complemento"
                          label="Complemento"
                          maxLength={200}
                        />
                      </ContainerMedio>
                      <ContainerMedio>
                        <InputUnform
                          id="ipt_bairro"
                          name="bairro"
                          label="Bairro"
                          obrigatorio
                          maxLength={200}
                        />
                      </ContainerMedio>
                    </Linha>
                    <Linha>
                      <ContainerMedio>
                        <InputUnform
                          id="ipt_cidade"
                          name="cidade"
                          label="Cidade"
                          obrigatorio
                          maxLength={200}
                        />
                      </ContainerMedio>
                      <ContainerMedio>
                        <InputUnform
                          id="ipt_uf"
                          name="uf"
                          label="UF"
                          obrigatorio
                        />
                      </ContainerMedio>
                    </Linha>
                  </Scope>
                </Secao>
                <Secao id={Navegacoes.DadosRepasse}>
                  <SubTitulo texto="Repasse" />
                  {classRepassList.map((classRepass, index) => (
                    <Linha key={index}>
                      <ContainerMedio>
                        <SelectAsyncUnform
                          id={`ipt_turma.${index}`}
                          name={`repassInfo.${index}.turmaId`}
                          label="Turma"
                          buscarPorTexto={RequisitarListaTurmasPorTexto}
                          buscarPorId={carregarTurmaPorId}
                          obrigatorio
                        />
                        <Padding />
                      </ContainerMedio>
                      <ContainerMedio>
                        <NumeroInputUnform
                          id={`ipt_repasse.${index}`}
                          name={`repassInfo.${index}.repass`}
                          label="Repasse"
                          isDecimal
                          obrigatorio
                        />
                      </ContainerMedio>
                      <ContainerPequeno>
                        {index > 0 && (
                          <button
                            type="button"
                            data-tip="Remover"
                            data-for={`${index}-tooltip-editar`}
                            onClick={e => {
                              e.preventDefault()
                              handleRemoveRepassList(index)
                            }}
                            style={{ marginTop: '25px' }}
                          >
                            {IconeLixeira}
                          </button>
                        )}
                      </ContainerPequeno>
                    </Linha>
                  ))}
                  <Linha>
                    <Botao
                      type="button"
                      texto="Adicionar Repasse"
                      onClick={() => handleClick()}
                      carregando={carregando}
                    />
                  </Linha>
                </Secao>
                <ContainerAcoes>
                  <Botao
                    texto="Cancelar"
                    tema="Secundario"
                    type="button"
                    disabled={carregando}
                    onClick={() => modalRef?.current?.abrir()}
                  />
                  <Botao type="submit" texto="Salvar" carregando={carregando} />
                </ContainerAcoes>
              </FormUnform>
            </Conteudo>
          </ContainerMain>
          <ContainerLateral>
            <Navegacao
              itens={[
                {
                  link: Navegacoes.DadosParceiro,
                  nome: 'Dados do Parceiro'
                },
                {
                  link: Navegacoes.DadosPagamento,
                  nome: 'Dados do Pagamento'
                },
                {
                  link: Navegacoes.DadosEndereco,
                  nome: 'Dados do Endereço'
                },
                {
                  link: Navegacoes.DadosRepasse,
                  nome: 'Dados do Repasse'
                }
              ]}
            />
          </ContainerLateral>
          <Modal
            ref={modalRef}
            backdrop
            id="modal-confirmacao-cancelamento"
            icone={<></>}
            titulo={'Deseja cancelar?'}
            acaoPrimaria={{
              titulo: 'Sim',
              tipo: 'button',
              acao: () => history.goBack()
            }}
            acaoSecundario={{
              titulo: 'Não',
              tipo: 'button',
              acao: () => modalRef?.current?.fechar()
            }}
          >
            <p>Selecione uma opção</p>
          </Modal>
        </Container>
      ) : (
        <></>
      )}
    </>
  )
}

export default withRouter(CadastroSplitPagamento)
