import { TipoMaterialComplementar } from 'src/componentes'
import { TipoDeAvaliacao } from 'src/dto'
import { DisciplinaComplementar } from 'src/servicos/api/tipos'

import { TipoProfessorModuloTCC } from '.'
import { ModalidadeCurso, TipoAvaliacao } from './curso'
import { Endereco } from './endereco'
import { TipoMaterial } from './material'

export enum TurmaSituacao {
  Aberta,
  AguardandoInicio,
  EmAndamento,
  Cancelada,
  Finalizada,
  Todos
}

export enum TurmaSituacaoPorExtenso {
  Aberta = 'Aberta',
  AguardandoInicio = 'Aguardando início',
  EmAndamento = 'Em andamento',
  Cancelada = 'Cancelada',
  Finalizada = 'Finalizada',
  Todos = 'Todas'
}

export interface DadosPesquisaTurma {
  codigo: string
  nome: string
  uf: string
  cidade: string
  situacao: keyof typeof TurmaSituacao
}

interface Material {
  id: string
  titulo: string
  ordem?: number
  tipoMaterial?: TipoMaterial
  url?: string
  nomeArquivo?: string
  tipoMaterialComplementar?: TipoMaterialComplementar
}

export interface Pergunta {
  id?: string
  texto: string
  respostas: {
    id?: string
    texto: string
    correta: boolean
  }[]
  anular?: boolean
  observacao?: string
  explicacao?: string
}

export interface Avaliacao {
  id?: string
  nome: string
  quantidadeTentativas: number
  descricao: string
  perguntas: Pergunta[]
  dataLiberacao: string
  dataLimite: string
  tipoAvaliacaoTurma: TipoDeAvaliacao
  chaveArquivo?: string
}

export interface EncontroPresencial {
  data: Date
  cargaHoraria: number
  id?: string
}

export interface ProfessorDisciplina {
  professorId: string
  nome: string
}

export interface ProfessorDisciplinaModuloTCC {
  professorId: string
  nome: string
  tipo: TipoProfessorModuloTCC
}

export interface DisciplinaTurma {
  disciplinaId: string
  nome: string
  cargaHoraria: number
  encontros?: EncontroPresencial[]
  materiais: Material[]
  avaliacao?: Avaliacao
  professores?: ProfessorDisciplina[]
  tutorDisciplina: boolean
  possuiAvaliacao?: boolean
}

export interface DisciplicaModuloTCCTurma extends DisciplinaTurma {
  professoresOrientador?: ProfessorDisciplinaModuloTCC[]
  professoresBanca?: ProfessorDisciplinaModuloTCC[]
}

export interface Modulo {
  moduloId?: string
  nome: string
  dataLiberacao?: string
  ordem: number
  tipoModulo?: string
  informacoesAdicionais?: string
  disciplinas: DisciplinaTurma[]
  avaliacao?: Avaliacao
  possuiAvaliacao?: boolean
}

export interface ModuloTCC {
  moduloId: string
  nome: string
  dataLiberacao?: string
  disciplinas: DisciplicaModuloTCCTurma[]
}

export interface Turma {
  id?: string
  nome: string
  codigo: string
  situacaoTurma?: TurmaSituacao
  grupoDiscussao?: string
  dataInicio: string
  dataFim: string
  dataInicioInscricao: string
  dataFimInscricao: string
  cursoId: string
  nomeCurso: string
  gradeCurricularId: string
  nomeGrade: string
  tutorId: string
  tipoAvaliacaoCurso: keyof typeof TipoAvaliacao
  modalidadeCurso: keyof typeof ModalidadeCurso
  endereco?: Omit<Endereco, 'pais'> & { local?: string }
  materiaisTurma: Material[]
  modulos: Modulo[]
  moduloTCC?: ModuloTCC
  nomeTurma?: string
  tutorTurma: boolean
  tutor?: string
  pesquisaSatisfacaoId?: string
  pesquisaSatisfacaoProfessoresId?: string
  materiaisComplementares: Material[]
  disciplinasComplementares: DisciplinaComplementar[]
  turmaReingressoId?: string
  sincronizarCrm?: boolean
  turmaSoCertificacao?: boolean
  turmaSplit?: boolean
}

export interface TurmaSplitItem {
  id?: string
  nomeContato: string
  nomeFantasia: string
  razaoSocial?: string
  agencia: string
  digitoAgencia?: string
  banco: string
  cnpj: string
  conta: string
  email: string
  merchantIdBraspag?: string
  endereco: {
    cep: string
    logradouro: string
    cidade: string
    uf: string
    complemento: string
    numero: number
    estado: string
    bairro: string
    municipio: string
  }
  telefoneContato: string
  tipoConta: string
  digitoConta?: string
  turmaRepasse?: Array<{
    turmaId: string
    repasse: string
  }>
  repassInfo?: Array<{
    turmaId: string
    repass: string
  }>
}

export interface TurmaSplit {
  itens: Array<TurmaSplitItem>
  quantidade: number
  opcoesPaginacao: {
    pagina: number
    totalRegistros: number
  }
}

export interface AlunosFinalizacao {
  idSalaAula: string
  nomeAluno: string
  dataInscricaoTCC?: Date
  professorOrientador?: string
  professorBanca?: string
  tituloTCC?: string
  possuiNotaOrientador: boolean
  possuiNotaBanca: boolean
  notificacaoOrientadorTCCEnviada: boolean
  notificacaoBancaTCCEnviada: boolean
}

export interface DadosAlunoFinalizacao {
  alunos: AlunosFinalizacao[]
}

export interface AtualizarProfessorTCC {
  salaAulaIds: string[]
  professorId: string
  tipoProfessor: TipoProfessorModuloTCC
  turmaId: string
}

export interface EnviarEmailProfessorTcc {
  salaAulaEadId: string
  tipoProfessor: TipoProfessorModuloTCC
}

export interface EnviarModuloFlexiveis {
  id: string
  ordem: number
}

export interface NovosModuloFlexiveis {
  moduloAntigo: string
  moduloEscolhido: string
}
