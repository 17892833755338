import { useMemo, useState } from 'react'

import { TipoMaterialComplementar } from 'src/componentes'
import { ModoPaginaCadastro, ModalidadeCurso, TurmaSituacao } from 'src/tipos'
import { createContainer } from 'unstated-next'
import { v4 } from 'uuid'

import {
  Turma,
  EncontroPresencialTurma,
  MaterialTurma,
  PerguntaTurma,
  TurmaContainerEstado,
  TurmaContainerEstadoInicial
} from './tipos'

const useTurma = (initialState: TurmaContainerEstadoInicial) => {
  const [estado, definirEstado] = useState<TurmaContainerEstado>({
    modo: ModoPaginaCadastro.Inclusao,
    ...initialState,
    perguntas: [],
    materiais: [],
    encontros: [],
    cursoId: null,
    turma: {} as Turma,
    materiaisAtualizados: [],
    disciplinasComplementares: []
  })

  const ehModoCadastro = useMemo(
    () => estado.modo === ModoPaginaCadastro.Inclusao,
    [estado.modo]
  )

  const ehModalidadeEad = useMemo(
    () =>
      estado.turma.modalidadeCurso === ModalidadeCurso.PosGraduacaoEad ||
      estado.turma.modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFlex ||
      estado.turma.modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFull ||
      estado.turma.modalidadeCurso ===
        ModalidadeCurso.PosGraduacaoEadFullIntegracaoInstitucional ||
      estado.turma.modalidadeCurso ===
        ModalidadeCurso.PosGraduacaoEadIntegracaoInstitucional ||
      estado.turma.modalidadeCurso === ModalidadeCurso.MBAEad ||
      estado.turma.modalidadeCurso === ModalidadeCurso.MBAEadFull,
    [estado.turma.modalidadeCurso]
  )

  const ehModalidadePosGraduacaoEad = useMemo(
    () => estado.turma.modalidadeCurso === ModalidadeCurso.PosGraduacaoEad,
    [estado.turma.modalidadeCurso]
  )

  const bloquearCampo = useMemo(
    () =>
      estado.turma.situacaoTurma === TurmaSituacao.Finalizada ||
      estado.turma.situacaoTurma === TurmaSituacao.Cancelada,
    [estado.turma.situacaoTurma]
  )

  const definirPerguntas = (perguntas: PerguntaTurma[]) => {
    definirEstado(old => ({ ...old, perguntas }))
  }

  const definirMateriais = (materiais: MaterialTurma[]) => {
    definirEstado(old => ({ ...old, materiais }))
  }

  const definirEncontros = (encontros: EncontroPresencialTurma[]) => {
    definirEstado(old => ({ ...old, encontros }))
  }

  const definirTurma = (turma: Turma) => {
    definirEstado(old => ({ ...old, turma }))
  }

  const definirCursoId = (cursoId: string) => {
    definirEstado(old => ({ ...old, cursoId }))
  }

  const adicionarAlteracaoMaterial = (
    idDisciplina?: string,
    tipoMaterialComplementar?: TipoMaterialComplementar
  ) => {
    definirEstado(old => ({
      ...old,
      materiaisAtualizados: [
        ...old.materiaisAtualizados,
        { idDisciplina, tipoMaterialComplementar }
      ]
    }))
  }

  const adicionarMaterial = (material: MaterialTurma) => {
    definirEstado(old => ({ ...old, materiais: [...old.materiais, material] }))
    adicionarAlteracaoMaterial(
      material.idDisciplina,
      material.tipoMaterialComplementar
    )
  }

  const removerMaterial = (
    index: number,
    idDisciplina: string,
    tipoMaterialComplementar?: TipoMaterialComplementar
  ) => {
    definirEstado(old => {
      const funcaoSort = function (a, b) {
        if (a.ordem < b.ordem) return -1
        if (a.ordem > b.ordem) return 1
        return 0
      }
      let material: MaterialTurma

      if (!tipoMaterialComplementar) {
        material = old.materiais
          .filter(m => !m.tipoMaterialComplementar)
          .sort(funcaoSort)
          .filter(m => m.idDisciplina === idDisciplina)
          .find((_, i) => i === index)
      } else {
        material = old.materiais
          .filter(m => m.tipoMaterialComplementar === tipoMaterialComplementar)
          .sort(funcaoSort)
          .find((_, i) => i === index)
      }

      const materiaisAtuais = material
        ? old.materiais.filter(m => m.id !== material.id)
        : old.materiais

      materiaisAtuais
        ?.sort(funcaoSort)
        .forEach((item, index) => (item.ordem = index + 1))

      return {
        ...old,
        materiais: materiaisAtuais
      }
    })
    adicionarAlteracaoMaterial(idDisciplina, tipoMaterialComplementar)
  }

  const adicionarPergunta = (pergunta: PerguntaTurma) => {
    definirEstado(old => ({
      ...old,
      perguntas: [
        ...old.perguntas.filter(x => x.id !== pergunta?.id),
        { id: pergunta.id ?? v4(), ...pergunta }
      ]
    }))
  }

  const removerPergunta = (id: string) =>
    definirEstado(old => ({
      ...old,
      perguntas: old.perguntas.filter(x => x.id !== id)
    }))

  const adicionarEncontro = (encontro: EncontroPresencialTurma) => {
    definirEstado(old => ({
      ...old,
      encontros: [
        ...old.encontros.filter(x => x.id !== encontro?.id),
        { id: encontro.id ?? v4(), ...encontro }
      ]
    }))
  }

  const removerEncontro = (id: string) =>
    definirEstado(old => ({
      ...old,
      encontros: old.encontros.filter(x => x.id !== id)
    }))

  const definirDisciplinaComplementar = (
    tipo: TipoMaterialComplementar,
    nome: string
  ) => {
    definirEstado(old => ({
      ...old,
      disciplinasComplementares: [
        ...old.disciplinasComplementares.filter(x => x.tipo !== tipo),
        { tipo, nome }
      ]
    }))
  }

  return {
    ...estado,
    ehModoCadastro,
    ehModalidadeEad,
    ehModalidadePosGraduacaoEad,
    bloquearCampo,
    definirPerguntas,
    definirMateriais,
    definirEncontros,
    definirTurma,
    definirCursoId,
    adicionarMaterial,
    removerMaterial,
    adicionarPergunta,
    removerPergunta,
    adicionarEncontro,
    removerEncontro,
    adicionarAlteracaoMaterial,
    definirDisciplinaComplementar
  }
}

export default createContainer(useTurma)
